import axios from 'axios';
import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const GalleryListing = ({ module }) => {
  const imageFixLimit = 12;
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [sliderGallery, setsliderGallery] = useState([]);
  const [galleryImagesToDisplay, setGalleryImagesToDisplay] =
    useState(imageFixLimit);

  useEffect(() => {
    setsliderGallery(
      module.tabs[selectedFilterIndex].galleryItems.slice(
        0,
        galleryImagesToDisplay
      )
    );
  }, []);

  const onFileterChange = (e) => {
    setSelectedFilterIndex(e.target.value);
    setGalleryImagesToDisplay(imageFixLimit);
  };

  const onLoadMoreClick = () => {
    setGalleryImagesToDisplay(galleryImagesToDisplay + imageFixLimit);
  };

  const downloadMedia = (e) => {
    const fileUrl = e.currentTarget.getAttribute('data-image-path');
    const fileName = e.currentTarget.getAttribute('data-image-name');

    axios(fileUrl, {
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        let completed = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        ); // you can use this to show user percentage of file downloaded
        setIsLoading(true);
        setPercentCompleted(completed);
      },
    })
      .then((res) => {
        return res.data;
      })
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      {isLoading && (
        <div className="fixed flex flex-col justify-center items-center top-0 w-full h-full bg-white bg-opacity-90 z-999">
          <div className="block text-32 mb-5">{percentCompleted}</div>
          <h4 className="block text-24 animate-pulse">
            Please wait while we prepare your download...
          </h4>
        </div>
      )}
      <section
        className={`img-grid gallery-img-grid portrait lg:py-60 py-30 ${module.extraClass}`}
      >
        <div className="container-fluid">
          <div
            className={`container m-auto hello ${
              module.hideFilter === 'Yes' ? 'hidden' : ''
            } `}
          >
            <div className="filter-list text-center mdscreen:pb-10 mdscreen:border-b-[1px] mdscreen:border-b-[#666366]/40 lg:mb-60 mb-30 relative">
              <select onChange={onFileterChange}>
                {module.tabs &&
                  module.tabs.map((item, index) => {
                    return (
                      <option key={index} value={index}>
                        {item.tabHeading}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="flex flex-wrap lg:mx-minus-20 lg:gap-y-20 gap-y-10 ">
            {module?.tabs?.length > 0 &&
              module.tabs[selectedFilterIndex] !== null &&
              module.tabs[selectedFilterIndex]?.galleryItems
                .slice(0, galleryImagesToDisplay)
                .map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={`lg:w-4/12 w-full lg:px-20 fade-img`}
                    >
                      {module.addVideo === 'Yes' ? (
                        <>
                          <div className="cursor-pointer relative">
                            <a
                              href={item.download}
                              target="_blank"
                              className="w-full h-full absolute z-[9999] flex items-center justify-center"
                            >
                              <img
                                className="max-w-[15px]"
                                src="../../../images/play-icon.png"
                              />
                            </a>
                            <img
                              src={item.image.imgixImage.url}
                              alt={
                                item?.image.altText ? item?.image?.altText : ''
                              }
                              loading="lazy"
                            />
                          </div>
                          <div className="img-content flex justify-between pt-30 items-center">
                            <span className="uppercase text-dark text-11 font-400 font-preheaders">
                              {item.caption}
                            </span>
                            <div className="download-icon">
                              <button
                                onClick={downloadMedia}
                                data-image-path={item.download}
                                data-image-name={item.caption}
                              >
                                <img
                                  className="max-w-[15px]"
                                  src="../../../images/download.png"
                                  alt=""
                                />
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setPhotoIndex(i);
                            setisOpen(true);
                          }}
                        >
                          <img
                            src={item.image.imgixImage.url}
                            alt={
                              item?.image.altText ? item?.image?.altText : ''
                            }
                            loading="lazy"
                          />
                        </div>
                      )}
                      {item.download ? (
                        <div className="img-content flex justify-between pt-30 items-center">
                          {module.addVideo === 'No' && (
                            <>
                              <span className="uppercase text-dark text-11 font-400 font-preheaders">
                                {item.caption}
                              </span>
                              <div className="download-icon">
                                <button
                                  onClick={downloadMedia}
                                  data-image-path={item.image.mediaItemUrl}
                                  data-image-name={item.image.filename}
                                >
                                  <img
                                    className="max-w-[15px]"
                                    src="../../../images/download.png"
                                    alt=""
                                  />
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="img-content pt-30 max-w-540">
                          <span className="uppercase text-dark text-11 font-400 font-preheaders">
                            {item.caption}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
          </div>

          {galleryImagesToDisplay <
            module.tabs[selectedFilterIndex]?.galleryItems?.length &&
            module.tabs[selectedFilterIndex]?.galleryItems?.length > 1 && (
              <div className="btn-custom text-center justify-center cursor-pointer lg:mt-80 mt-40">
                <a
                  onClick={onLoadMoreClick}
                  className="button button-transparent bg-[#e2e2e2]"
                >
                  Load More
                </a>
              </div>
            )}
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={sliderGallery[photoIndex].image.imgixImage.url}
            nextSrc={sliderGallery[(photoIndex + 1) % sliderGallery.length]}
            onImageLoadError={() => {}}
            imageLoadErrorMessage={() => {}}
            prevSrc={
              sliderGallery[
                (photoIndex + sliderGallery.length - 1) % sliderGallery.length
              ]
            }
            onCloseRequest={() => setisOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex) =>
                  (photoIndex + sliderGallery.length - 1) % sliderGallery.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex(
                (photoIndex) => (photoIndex + 1) % sliderGallery.length
              )
            }
          />
        )}
      </section>
    </>
  );
};

export default GalleryListing;

export const GalleryListingFragment = graphql`
  fragment GalleryListingFragment on WpPage_Flexiblecontent_FlexibleContent_GalleryListing {
    # content
    extraClass
    hideFilter
    fieldGroupName
    addVideo
    tabs {
      fieldGroupName
      galleryItems {
        caption
        download
        fieldGroupName
        image {
          filename
          altText
          imgixImage {
            url(imgixParams: { w: 1100, h: 785, q: 95, fit: "crop" })
          }
          mediaItemUrl
        }
      }
      tabHeading
    }
  }
`;

export const GalleryListingPressFragment = graphql`
  fragment GalleryListingPressFragment on WpPress_Flexiblecontent_FlexibleContent_GalleryListing {
    # content
    extraClass
    hideFilter
    addVideo
    fieldGroupName
    tabs {
      fieldGroupName
      galleryItems {
        caption
        download
        fieldGroupName
        image {
          filename
          altText
          imgixImage {
            url(imgixParams: { w: 1100, h: 785, q: 95, fit: "crop" })
          }
          mediaItemUrl
        }
      }
      tabHeading
    }
  }
`;
