import { graphql } from 'gatsby';
import React from 'react';
import ColumnListing from '../components/ColumnListing';
import GalleryListing from '../components/GalleryListing';
import GlobalGallery from '../components/GlobalGallery';
import IntroContent from '../components/IntroContent';
import PressReleases from '../components/PressReleases';
import RelatedCards from '../components/RelatedCards';

const Press = (props) => {
  const modules = props.modules.flexibleContent;
  const { location } = props;
  const components = {
    IntroContent,
    ColumnListing,
    PressReleases,
    RelatedCards,
    GalleryListing,
    GlobalGallery,
  };
  return (
    <>
      {modules
        ? modules.map((module, i) => {
            const moduleName = module.__typename.replace(
              'WpPress_Flexiblecontent_FlexibleContent_',
              ''
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], {
                key: i,
                module,
                location,
                i,
              })
            );
          })
        : null}
    </>
  );
};

export default Press;

export const PressModulesFragment = graphql`
  fragment PressModulesFragment on WpPress {
    flexibleContent {
      flexibleContent {
        __typename
        ...IntroContentPressFragment
        ...ColumnListingPressFragment
        ...RelatedCardsPressFragment
        ...GalleryListingPressFragment
        ...PressReleasesFragment
        ...GlobalGalleryPressFragment
      }
    }
  }
`;
